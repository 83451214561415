import React, { useContext,useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../images/Phius-Logo.png';
import { faAward, faArrowUpRightFromSquare, faBox, faCalendar, faEnvelope, faFolder, faHome, faUsers } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const userContext = useContext(UserContext);
  const { first_name, last_name, phius_id, email} = userContext?.currentUser;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize(); // Initialize state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navBarDisplay = (user) => {
    const memberType = user.profile_type;
    
    const commonNavItems = [
      { to: '/dashboard', icon: faHome, label: 'Dashboard' },
      { to: '/events', icon: faCalendar, label: 'Events' },
      { to: '/reviewer', icon: faBox, label: 'Projects & Products' },
      { to: '/resources', icon: faFolder, label: 'Resources & Guidebook' },
    ];

    const contactPhiusItem = {
      href: `https://forms.zohopublic.com/phius/form/ContactPhius/formperma/_u3NnJ8_l4Bcia3a4bHvPvSJQU7OKKtq-LLaVhyKeuM?FIRST=${first_name}&LAST=${last_name}&phiusid=${phius_id}&EMAIL=${email}`,
      icon: faEnvelope,
      label: 'Contact Phius',
      external: true
    };
  
    const navItemsByMemberType = {
      Submitter: [
        ...commonNavItems,
        { href: 'https://www.phius.org/alliance/membership', icon: faUsers, label: 'Become a Member', external: true }
      ],
      'Certified Professional': [
        ...commonNavItems,
        { to: '/certification', icon: faAward, label: 'Certification Status' }
      ],
      Trainee: [
        ...commonNavItems,
        { to: '/certification', icon: faAward, label: 'Certification Status' }
      ],
      Reviewer: [
        ...commonNavItems,
      ],
      Member: [
        ...commonNavItems,
        { to: '/certification', icon: faAward, label: 'Certification Status' }
      ],
      Default: [
        ...commonNavItems,
        { href: 'https://www.phius.org/alliance/membership', icon: faUsers, label: 'Become a Member', external: true }
      ]
    };
  
    const navItems = [...(navItemsByMemberType[memberType] || navItemsByMemberType.Default), contactPhiusItem];
  
    return (
      <ul className="nav-items">
        {navItems.map((item, index) => (
          <li key={index}>
            {item.external ? (
              <a target="_blank" rel="noreferrer" href={item.href}>
                <FontAwesomeIcon icon={item.icon} /> {item.label} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            ) : (
              <NavLink to={item.to}>
                <FontAwesomeIcon icon={item.icon} /> {item.label}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={`navbar-container ${isMobile ? 'hidden' : ''}`}>
      <div className='header-logo-container header-align-content flex-center'>
        <h3 className='header-align-content flex-center header-logo-container'>
          <NavLink className='header-logo header-align-content' to='/'>
            <img src={logo} alt='logo' />
          </NavLink>
        </h3>
      </div>
      {userContext.currentUser !== undefined
        ? navBarDisplay(userContext.currentUser)
        : navBarDisplay('other')}
    </div>
  );
};

export default NavBar;
