import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faMap, faBoxArchive, faCalculator, faGlobeAmericas, faBinoculars, faCity, faHome, faAward, faVideo } from '@fortawesome/free-solid-svg-icons';
import { BsBuildingsFill } from "react-icons/bs";
import { FiLoader } from "react-icons/fi";
import { PiBuildingApartmentFill } from "react-icons/pi";
import './resourcesPage.css'

const data = [
    {
      title: 'Featured',
      cards: [
        {
          id: 1,
          text: 'Resource Library',
          icon: faBookOpen,
          iconColor: '#00AAAF', //teal
          url: 'https://www.phius.org/resource-library',
        },
        {
          id: 2,
          text: 'Phius Certification Guidebook',
          icon: faMap,
          iconColor: '#6E4692', //purple
          url: 'https://www.phius.org/phius-certification-guidebook',
        },
        {
            id: 3,
            text: 'Phius Certification Guidebook Archive',
            icon: faBoxArchive,
            iconColor: '#5F78BB', //blue
            url: 'https://www.phius.org/phius-certification-guidebook',
          },
          {
            id: 4,
            text: 'Calculators and Protocols',
            icon: faCalculator,
            iconColor: '#FFCF34', //yellow
            url: 'https://www.phius.org/resources/tools-and-support/calculators-and-protocols',
          },
      ],
    },
    {
      title: 'Phius Project Certification Process',
      cards: [
        {
          id: 5,
          text: 'Climate Data',
          icon: faGlobeAmericas,
          iconColor: '#0E2746', //dark blue
          url: 'https://www.phius.org/climate-data-sets',
        },
        {
          id: 6,
          text: 'Feasibility Study',
          icon: faBinoculars,
          iconColor: '#00AAAF',
          url: 'https://www.phius.org/resources/tools-and-support/feasibility-study',
        },
        {
            id: 7,
            text: 'Submit A Project',
            icon: faCity,
            iconColor: '#6E4692',
            url: 'https://www.phius.org/certifications/projects/submit-project',
          },
          {
            id: 8,
            text: 'Cert Review Queue',
            icon: FiLoader,
            iconColor: '#5F78BB',
            url: 'https://www.phius.org/certifications/projects/certification-review-queue',
          },
      ],
    },
    {
        title: 'Phius Certified Professionals',
        cards: [
            {
                id: 9,
                text: 'Maintain Credentials',
                icon: faAward,
                iconColor: '#FFCF34',
                url: 'https://www.phius.org/certifications/professionals/maintain-credentials',
              },
              {
                id: 10,
                text: 'View past webinars for CEUS',
                icon: faVideo,
                iconColor: '#0E2746',
                url: 'https://www.phius.org/resource-library?_page=1&keywords=&_limit=10&ceu_credits_bool=true&resource_type=9',
              },
              {
                id: 11,
                text: 'Single Family Quality Assurance Workbook',
                icon: faHome,
                iconColor: '#00AAAF',
                url: 'https://www.phius.org/phius-single-family-quality-assurance-workbook',
            },
            {
                id: 12,
                text: 'Multifamily Quality Assurance Workbook',
                icon: PiBuildingApartmentFill,
                iconColor: '#6E4692',
                url: 'https://www.phius.org/phius-multifamily-quality-assurance-workbook',
            },
            {
                id: 13,
                text: 'Non-Residential Quality Assurance Workbook',
                icon: BsBuildingsFill,
                iconColor: '#5F78BB',
                url: 'https://www.phius.org/phius-non-residential-quality-assurance-workbook',
            },
        ],
      },
  ];

  const IconSwitcher = ({ icon, ...rest }) => {
    if (icon && typeof icon === 'object' && icon.prefix) {
      return <FontAwesomeIcon icon={icon} {...rest} />;
    }
  
    // Otherwise assume it's a React component (for example, from react-icons)
    // Make sure that the icon is valid before rendering.
    const IconComponent = icon;
    return <IconComponent {...rest} />;
  };

  const Card = ({ text, icon, iconColor, url }) => {
    return (
    <div className="card" onClick={() => window.open(url, '_blank')}>
      <div className="card-icon" style={{ color: iconColor }}>
        <IconSwitcher icon={icon} />
      </div>
      <div className="card-text">{text}</div>
    </div>
    )
  };

export default function resourcesPage() {
  return (
    <div className="card-body resources-page">
      <div className="flex-column flex-center background-opacity">
      <div className="container">
        <h1>Resources & Guidebook</h1>
      {data.map((row, index) => (
        <div key={index} className="row">
          <h2>{row.title}</h2>
          <div className="cards-container">
            {row.cards.map((card) => (
              <Card key={card.id} {...card} />
            ))}
          </div>
        </div>
      ))}
    </div>
      </div>
    </div>
  )
}