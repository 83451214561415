import React, { useEffect, useContext } from "react"
import { Link } from 'react-router-dom'
import TokenService from '../../services/token-service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import UserContext from '../../contexts/UserContext'
import UserService from "../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";


// export class TopBar extends Component {
const TopBar = () => {
  // const [user,setUser] = useState()
  const userContext = useContext(UserContext)

  const { user,logout} = useAuth0();

  const handleLogoutClick = () => 
  {
    TokenService.clearAuthToken();
    userContext.setCurrentUser({});
    logout({ logoutParams: { returnTo: window.location.origin } })
  };
  const handleauthLogoutClick = () => 
  {
    TokenService.clearAuthToken();
    userContext.setCurrentUser({});
    logout({ logoutParams: { returnTo: window.location.origin } })
  };

  useEffect(() => 
  {
    if (TokenService.hasAuthToken() === false) 
    {
      // TokenService.clearAuthToken();
      userContext.setCurrentUser({});
    }
    else {
      UserService.getUserByToken()
        .then(res => {
          userContext.setCurrentUser(res.user)
        })
        .catch(e =>{
          console.error(e)
          TokenService.clearAuthToken();
          userContext.setCurrentUser({});
        })
    }
    // console.log("TOKEN:",TokenService.getAuthToken())
  }, [])

  // log user out if context is empty
  useEffect(() => {
    userContext.userId === null ? TokenService.clearAuthToken() : TokenService.getAuthToken()
  }, [userContext.currentUser]);

  let renderActions = (contextObj) => {
    // console.log("top render:", contextObj)
    return (
      TokenService.hasAuthToken() ? (<div className="display-flex header-align-content header-user-content">
        {/* change this to user_id after demo */}
        {/* <Link to={`/profile/${contextObj.currentUser.user_id}`} className="remove-text-decoration"> */}
        <a className="remove-text-decoration training-link-style" style={{color: 'white'}} target="_blank" rel="noreferrer" href={'https://training.phius.org'}>
          Phius Training Site <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> |
        </a>
        <Link to={`/`} className="remove-text-decoration">
          <p className="username-style">{contextObj.currentUser.first_name} {contextObj.currentUser.last_name}</p>
        </Link>
        {/* <div className="image-cropper margin-left-of-div">
          <img src={contextObj.currentUser.profilepicture} alt={contextObj.currentUser.firstname} className="profile-pic" width="50" height="50" />
        </div> */}
        <Link className="username-style remove-text-decoration margin-left" onClick={handleLogoutClick} to='/'>
          <FontAwesomeIcon size='xs' color="white" icon='sign-out-alt' />
          {/* <span className='tooltiptext'>Log Out</span> */}
        </Link>
      </div>) : user ? (<><Link className="username-style remove-text-decoration margin-right" to='/'>{user ? (user.name) : "Login"}</Link><Link className="username-style remove-text-decoration margin-left" onClick={handleauthLogoutClick} to='/'>
          <FontAwesomeIcon size='xs' color="white" icon='sign-out-alt' /></Link></>) : "Login"
      
    )
  }
  // render() {
  return (
    <div className="header-profile-bar-container">
      {/* <div className="header-logo-container header-align-content flex-center">
        <h3 className="header-align-content flex-center header-logo-container"><NavLink className="header-logo header-align-content" to='/'><img src={logo} alt="logo" /></NavLink></h3>
      </div> */}
      {/* <div className="flexbox-column header-right"> */}
      <UserContext.Consumer>
        {value => renderActions(value)}
      </UserContext.Consumer>
      {/* <NotificationBar /> */}
      {/* </div> */}
    </div>
  )
  // }
}

export default TopBar