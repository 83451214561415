import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

export class MembershipCTA extends Component {
  render() {
    return (
      <div className='card'>
        <div className='card-container'>
          <div className='icon-title-container'>
            <FontAwesomeIcon size='2xl' icon={faUsers} color='#00AAAF' />
            <p>
            Become a Phius Alliance Member
            </p>
          </div>
          <div className='card-body'>
            <div className='m1'>
              <p className='nonBold'>
                Want to connect with other Phius professionals in your area and
                across the globe? Become an Alliance Member and get exclusive
                access to programming, special events, and Phius staff. Build
                the Phius Community and your knowledge in the Alliance!{' '}
              </p>
            </div>
            <div>
              <a href='https://www.phius.org/alliance/membership'>
                <button className='button-cta-v2' type='button'>
                  Join Now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MembershipCTA;
