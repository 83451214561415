import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { LuBadgeCheck } from "react-icons/lu";
import MembershipCTA from '../membershipCTA/MembershipCTA';
import TokenService from '../../services/token-service';
import UserService from '../../services/UserService';
import UserContext from '../../contexts/UserContext';
import './MyDashboard.css';
import { useAuth0 } from "@auth0/auth0-react";

export default function MyDashboard() 
{
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [user, setUser] = useState({});
  const [crmEdit, setCrmEdit] = useState(false);
  const [respMsg, setRespMsg] = useState('');
  const {isAuthenticated,logout} = useAuth0();

  let myCertifications, becomeAMember, membership;

  const handleUpdateUser = (e) => {
    let { name, value } = e.target;
    if (name === 'cphb_licensed_insured') {
      value = value === 'true' ? true : false;
    }
    console.log(name, ' : ', value);
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSelectChange = (name, o) => {
    console.log('O: ', o);
    // const name = o[0].name
    const newOptions = o?.map((option) => option.value);
    console.log(name, ' : ', newOptions);
    setUser({
      ...user,
      [name]: newOptions,
    });
    // userContext.setCurrentUser(user)
  };

  const handleSubmitContactUpdate = () => {
    UserService.updateUser(user).then((res) => {
      console.log(res);
      setRespMsg(res.code);
    });
  };

  useEffect(() => 
  {
    if(isAuthenticated)
    {
      UserService.getUserByToken()
      .then((res) => {
        console.log('GET USER BY TOKEN: ', res);
        if (userContext.currentUser !== res.user) {
          if (userContext.currentUser !== res.user) {
            userContext.setCurrentUser(res.user);
          }
        }
        if (user?.crm_contact_id !== res.user?.crm_contact_id) {
          if (user !== res.user) {
            setUser(res.user);
          }
        }
      })
      .catch((e) => {
        console.error(e);
        TokenService.clearAuthToken();
        userContext.setCurrentUser({});
        history.push('/');
      });
    }else{
        TokenService.clearAuthToken();
        userContext.setCurrentUser({});
        logout({ logoutParams: { returnTo: window.location.origin } })
    }
  }, [history, isAuthenticated, logout, user, userContext]);

  const certifications =
    user?.certifications !== undefined
      ? user?.certifications?.map((cert, idx) => {
          const certificationString =
            user[`${cert.toLowerCase()}_certification_date`];
          const certificationDate = new Date(
            certificationString
          ).toLocaleDateString('en-US', { timeZone: 'UTC' });
          const expirationString =
            user[`${cert.toLowerCase()}_expiration_date`];
          const ceuTotal = user[`${cert.toLowerCase()}_ceu_total`];
          const expirationDate = new Date(expirationString).toLocaleDateString(
            'en-US',
            { timeZone: 'UTC' }
          );
          // console.log(certificationString,expirationString)
          return (
            <div key={idx} className='card-body' style={{ padding: '0.5rem' }}>
              <h2 className='uppercase certification-title responsiveText'>
                {cert}
              </h2>
              <hr />
              <div className='display-flex'>
                <p>
                  <span className='bolder responsiveText'>
                    Certification Date:{' '}
                  </span>
                  {certificationDate}
                </p>
                <p>
                  <span className='bolder responsiveText'>
                    Expiration Date:{' '}
                  </span>
                  {expirationDate}
                </p>
              </div>
              <p>
                <span className='bolder responsiveText'>
                  Current {cert} CEU Total:{' '}
                </span>
                {ceuTotal}
              </p>
              <Link
                className='cta-button3 remove-text-decoration detailButton'
                to='/certification'
              >
                Detailed CEU Count
              </Link>
            </div>
          );
        })
      : null;

  becomeAMember =
    user?.profile_type !== 'Member' && user?.profile_type !== 'Reviewer' ? (
      <MembershipCTA />
    ) : null;
  myCertifications =
    user?.profile_type !== 'Reviewer' &&
    user?.profile_type !== 'Submitter' &&
    certifications &&
    certifications.length > 0 ? (
      <section className='card height-100-percent'>
        <div className='card-header-grey display-flex'>
          <p className='card-header-title-style-gray'>Certifications</p>
        </div>
        <div className='profile-certifications-overflow height-100-percent'>
          {certifications}
        </div>
      </section>
    ) : null;
  const allianceChapters = [
    '-None-',
    'Austin',
    'Chicago',
    'Houston',
    'New York',
    'Wisconsin',
    'Minnesota',
    'Cascadia',
    'Rhode Island',
    'Southwest',
    // 'Atlanta',
    // 'Austin',
    // 'Capital',
    // 'Chicago',
    // 'Dayton',
    // 'Denver',
    // 'Houston',
    // 'Hudson Valley',
    // 'Kansas city',
    // 'Kentucky',
    // 'Lansing',
    // 'Michigan',
    // 'Minneapolis',
    // 'N Rockies',
    // 'None',
    // 'Portland',
    // 'San Diego',
    // 'San Francisco',
    // 'St Louis',
    // 'Vermont',
    // 'Wisconsin',
  ];
  const allianceChapterOptions = allianceChapters?.map((chapter) => {
    return (
      <option key={chapter} value={chapter}>
        {chapter}
      </option>
    );
  });

  membership =
    user?.profile_type === 'Member' ? (
      <div className='card'>
        <div className='title-edit-container'>
          <p>My Alliance Membership</p>
        </div>
        <div>
          <table className='profile-card-dashboard-seconary-table'>
            <tbody>
              <tr>
                <td className='profile-bold-th'>Member Type</td>
                <td>{user?.member_type}</td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Expiration Date</td>
                <td>
                  {new Date(user?.membership_expiration).toLocaleDateString(
                    'en-US',
                    { timeZone: 'UTC' }
                  )}
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Alliance Chapter</td>
                <td>
                  <select
                    name='alliance_chapter'
                    disabled={!crmEdit}
                    onChange={(e) => handleUpdateUser(e)}
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    defaultValue={
                      user?.alliance_chapter !== undefined
                        ? user?.alliance_chapter
                        : '-None-'
                    }
                  >
                    {allianceChapterOptions}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
          {/* <button className="cta-button detailButton"><a style={{ textDecoration: "none", color: "white" }} target="_blank" rel="noopener noreferrer" href="https://www.zoho.com/us/subscriptions/">RENEW</a></button> */}
        </div>
      </div>
    ) : null;

  const profileCard = (
    <div className='card'>
          <div className='title-edit-container'>
            <p>
              My Profile
            </p>
          </div>
          {respMsg === 'SUCCESS' ? (
            <p
              style={{
                display: 'block',
                margin: '0.5em auto',
                textAlign: 'center',
                color: 'green',
              }}
            >
              {respMsg}
            </p>
          ) : (
            <p
              style={{
                display: 'block',
                margin: 'auto 0.5em',
                textAlign: 'center',
                color: 'red',
              }}
            >
              {respMsg}
            </p>
          )}
          <table className='profile-card-dashboard-table'>
            <tbody>
              <tr>
                <td className='profile-bold-th profile-td'>Name {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td className='bolder larger'>
                  <input
                    className={
                      crmEdit
                        ? 'edit-input display-inline bolder br0'
                        : 'dashboard-input display-inline bolder'
                    }
                    disabled={!crmEdit}
                    name='first_name'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.first_name}
                  />
                  <input
                    className={
                      crmEdit
                        ? 'edit-input display-inline bolder bl0'
                        : 'dashboard-input display-inline bolder'
                    }
                    disabled={!crmEdit}
                    name='last_name'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.last_name}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>Company (Account) {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td>
                  <a target='_blank' rel="noreferrer" href={`https://forms.zohopublic.com/phius/form/PhiusPortalCompanyAccountUpdate/formperma/bKjARdKKxaDQfbUAHwqqmZKy1e8mVm2p2Cm4G4cvaXs?phiusid=${user?.phius_id}`}>
                    {user?.crm_account_name}
                  </a>
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>Credentials {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                  <Select
                    name='credentials'
                    defaultValue={user?.credentials?.map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    onChange={(option) =>
                      handleSelectChange('credentials', option)
                    }
                    closeMenuOnSelect={false}
                    isMulti={true}
                    isDisabled={!crmEdit}
                    options={[
                      { value: '-None-', label: '-None-' },
                      { value: 'AIA', label: 'AIA' },
                      { label: 'FAIA', value: 'FAIA' },
                      { label: 'MA', value: 'MA' },
                      { label: 'MS', value: 'MS' },
                      { label: 'NCARB', value: 'NCARB' },
                      { label: 'PE', value: 'PE' },
                      { label: 'PhD', value: 'PhD' },
                      { label: 'RA', value: 'RA' },
                    ]}
                  />
              </tr>
              <tr>
                <td className='profile-bold-th'>Profile Type</td>
                <td>{user?.profile_type}</td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Phius ID</td>
                <td>{user?.phius_id}</td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Alliance Member</td>
                <td>{user?.phaus_member === true ? 'Yes' : 'No'}</td>
              </tr>
            </tbody>
          </table>
        </div>
  );

  const professionalInformationCard = (
    <div className='card'>
          <div className='title-edit-container'>
            <p className='color-navy'>
              Professional Information
            </p>
              {crmEdit ? (
              null
            ) : (
              <button onClick={() => setCrmEdit(true)} className='link-style-button'>
                Edit
              </button>
            )}
          </div>
          <table className='profile-card-dashboard-table pi-font-size'>
            <tbody>
              <tr>
                <td className='profile-bold-th'>Industry</td>
                <td className='profile-td'>
                  <select
                    name='industry'
                    disabled={!crmEdit}
                    onChange={(e) => handleUpdateUser(e)}
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    defaultValue={user?.industry}
                  >
                    <option value='-None-'>-None-</option>
                    <option value='Architecture'>Architecture</option>
                    <option value='Building Science'>Building Science</option>
                    <option value='Construction'>Construction</option>
                    <option value='Developer'>Developer</option>
                    <option value='Education'>Education</option>
                    <option value='Energy Rating'>Energy Rating</option>
                    <option value='Engineering'>Engineering</option>
                    <option value='Environmental Science'>
                      Environmental Science
                    </option>
                    <option value='Government'>Government</option>
                    <option value='Manufacturing'>Manufacturing</option>
                    <option value='Other'>Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>Areas Served {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                {/* multi select */}
                <td>
                  {user?.areas_serviced?.length !== 0 ||
                  user?.areas_serviced !== null ? (
                    <Select
                      name='areas_serviced'
                      defaultValue={user?.areas_serviced?.map((type) => ({
                        value: type,
                        label: type,
                      }))}
                      onChange={(option) =>
                        handleSelectChange('areas_serviced', option)
                      }
                      closeMenuOnSelect={false}
                      isMulti={true}
                      isDisabled={!crmEdit}
                      options={[
                        { value: 'All US States', label: 'All US States' },
                        {
                          value: 'All Canadian Provinces & Territories',
                          label: 'All Canadian Provinces & Territories',
                        },
                        { value: 'Alabama', label: 'Alabama' },
                        { value: 'Alaska', label: 'Alaska' },
                        { value: 'Arizona', label: 'Arizona' },
                        { value: 'Arkansas', label: 'Arkansas' },
                        { value: 'California', label: 'California' },
                        { value: 'Colorado', label: 'Colorado' },
                        { value: 'Connecticut', label: 'Connecticut' },
                        { value: 'Delaware', label: 'Delaware' },
                        { value: 'Florida', label: 'Florida' },
                        { value: 'Georgia', label: 'Georgia' },
                        { value: 'Hawaii', label: 'Hawaii' },
                        { value: 'Idaho', label: 'Idaho' },
                        { value: 'Illinois', label: 'Illinois' },
                        { value: 'Indiana', label: 'Indiana' },
                        { value: 'Iowa', label: 'Iowa' },
                        { value: 'Kansas', label: 'Kansas' },
                        { value: 'Kentucky', label: 'Kentucky' },
                        { value: 'Louisiana', label: 'Louisiana' },
                        { value: 'Maine', label: 'Maine' },
                        { value: 'Maryland', label: 'Maryland' },
                        { value: 'Massachusetts', label: 'Massachusetts' },
                        { value: 'Michigan', label: 'Michigan' },
                        { value: 'Minnesota', label: 'Minnesota' },
                        { value: 'Mississippi', label: 'Mississippi' },
                        { value: 'Missouri', label: 'Missouri' },
                        { value: 'Montana', label: 'Montana' },
                        { value: 'Nebraska', label: 'Nebraska' },
                        { value: 'Nevada', label: 'Nevada' },
                        { value: 'New Hampshire', label: 'New Hampshire' },
                        { value: 'New Jersey', label: 'New Jersey' },
                        { value: 'New Mexico', label: 'New Mexico' },
                        { value: 'New York', label: 'New York' },
                        { value: 'North Carolina', label: 'North Carolina' },
                        { value: 'North Dakota', label: 'North Dakota' },
                        { value: 'Ohio', label: 'Ohio' },
                        { value: 'Oklahoma', label: 'Oklahoma' },
                        { value: 'Oregon', label: 'Oregon' },
                        { value: 'Pennsylvania', label: 'Pennsylvania' },
                        { value: 'Rhode Island', label: 'Rhode Island' },
                        { value: 'South Carolina', label: 'South Carolina' },
                        { value: 'South Dakota', label: 'South Dakota' },
                        { value: 'Tennessee', label: 'Tennessee' },
                        { value: 'Texas', label: 'Texas' },
                        { value: 'Utah', label: 'Utah' },
                        { value: 'Vermont', label: 'Vermont' },
                        { value: 'Virginia', label: 'Virginia' },
                        { value: 'Washington', label: 'Washington' },
                        { value: 'West Virginia', label: 'West Virginia' },
                        { value: 'Wisconsin', label: 'Wisconsin' },
                        { value: 'Wyoming', label: 'Wyoming' },
                        { value: 'Alberta', label: 'Alberta' },
                        {
                          value: 'British Columbia',
                          label: 'British Columbia',
                        },
                        { value: 'Manitoba', label: 'Manitoba' },
                        { value: 'New Brunswick', label: 'New Brunswick' },
                        {
                          value: 'Newfoundland and Labrador',
                          label: 'Newfoundland and Labrador',
                        },
                        { value: 'Nova Scotia', label: 'Nova Scotia' },
                        { value: 'Ontario', label: 'Ontario' },
                        {
                          value: 'Prince Edward Island',
                          label: 'Prince Edward Island',
                        },
                        { value: 'Quebec', label: 'Quebec' },
                        { value: 'Saskatchewan', label: 'Saskatchewan' },
                        {
                          value: 'Northwest Territories',
                          label: 'Northwest Territories',
                        },
                        { value: 'Nunavut', label: 'Nunavut' },
                        { value: 'Yukon', label: 'Yukon' },
                        { value: 'Japan', label: 'Japan' },
                        { value: 'Bahrain', label: 'Bahrain' },
                        { value: 'Mexico', label: 'Mexico' },
                        { value: 'Chile', label: 'Chile' },
                        { value: 'Spain', label: 'Spain' },
                      ]}
                    />
                  ) : (
                    ''
                  )}
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>AIA #</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='AIA'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.AIA}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>BPI #</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='BPI'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.BPI}
                  />
                </td>
              </tr>
              {user?.certifications?.includes('CPHB') ? (
                <tr>
                  <td className='profile-bold-th profile-td'>
                    CPHB Licensed/Insured
                    {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}
                  </td>
                  <td>
                    <select
                      name='cphb_licensed_insured'
                      disabled={!crmEdit}
                      onChange={(e) => handleUpdateUser(e)}
                      className={crmEdit ? 'edit-input' : 'dashboard-input'}
                      defaultValue={user?.cphb_licensed_insured}
                    >
                      <option value={Boolean(true)}>Yes</option>
                      <option value={Boolean(false)}>No</option>
                    </select>
                  </td>
                </tr>
              ) : (
                ''
              )}
              <tr>
                <td className='profile-bold-th'>RESNET ID</td>
                <td>
                  <input
                    type='number'
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='resnet_id'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.resnet_id}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>RESNET Provider Name</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='resnet_provider_name'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.resnet_provider_name}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>RESNET Provider Email</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='resnet_provider_email'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.resnet_provider_email}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>
                  RESNET Status & Standing
                </td>
                <td>
                  <select
                    name='resnet_status'
                    disabled={!crmEdit}
                    onChange={(e) => handleUpdateUser(e)}
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    defaultValue={
                      user?.resnet_status !== undefined
                        ? user?.resnet_status
                        : '-None-'
                    }
                  >
                    <option value='-None-'>-None-</option>
                    <option value='Active'>Active</option>
                    <option value='Inactive'>Inactive</option>
                    <option value='Pending'>Pending</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>
                  Multifamily Designation
                  {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}
                </td>
                <td>
                  <select
                    name='multifamily_designation'
                    disabled={!crmEdit}
                    onChange={(e) => handleUpdateUser(e)}
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    defaultValue={user?.multifamily_designation ?? null}
                  >
                    <option value={null}>{''}</option>
                    <option value={Boolean(true)}>Yes</option>
                    <option value={Boolean(false)}>No</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          {crmEdit ? (
            <div className='edit-button-container'>
            <button
                onClick={() => setCrmEdit(false)}
                className='button-v2-secondary'
                type='button'
              >
                Cancel
              </button>
            <button
                onClick={handleSubmitContactUpdate}
                className='button-v2-primary'
                type='button'
              >
                Save
              </button>
            </div>
          ) : (null)}
        </div>
  );

  const contactInformationCard = (
        <div className='card'>
          <div className='title-edit-container'>
            <p className='color-navy'>
              Contact Information
            </p>
            {crmEdit ? (
             null
            ) : (
              <button onClick={() => setCrmEdit(true)} className='link-style-button'>
                Edit
              </button>
            )}
          </div>
          <table className='profile-card-dashboard-table pi-font-size'>
            <tbody>
            <tr>
                <td className='profile-bold-th'>Phone</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='phone'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.phone}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Preferred Email</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='email'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.email}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>Public Email {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='public_email'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.public_email}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>Website {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='website'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.website}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Address</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='street_address'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.street_address}
                  />
                </td>
              </tr>
              {user?.street_address_2 !== null ? (
                <tr>
                  <td className='profile-bold-th'>Address 2</td>
                  <td>
                    <input
                      className={crmEdit ? 'edit-input' : 'dashboard-input'}
                      disabled={!crmEdit}
                      name='street_address_2'
                      onChange={(e) => handleUpdateUser(e)}
                      value={user?.street_address_2}
                    />
                  </td>
                </tr>
              ) : (
                ''
              )}
              <tr>
                <td className='profile-bold-th profile-td'>City {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='city'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.city}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>State (initials) {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='state'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.state}
                    maxLength={"2"}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th'>Zip Code</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='postal_code'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.postal_code}
                  />
                </td>
              </tr>
              <tr>
                <td className='profile-bold-th profile-td'>Country {user?.profile_type === 'Certified Professional' && ( <LuBadgeCheck color="#00AAAF"/>)}</td>
                <td>
                  <input
                    className={crmEdit ? 'edit-input' : 'dashboard-input'}
                    disabled={!crmEdit}
                    name='country'
                    onChange={(e) => handleUpdateUser(e)}
                    value={user?.country}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {crmEdit ? (
            <div className='edit-button-container'>
            <button
                onClick={() => setCrmEdit(false)}
                className='button-v2-secondary'
                type='button'
              >
                Cancel
              </button>
            <button
                onClick={handleSubmitContactUpdate}
                className='button-v2-primary'
                type='button'
              >
                Save
              </button>
            </div>
          ) : (null)}
        </div>
  );

  const reportCEUCredits = (
    <div className='card'>
        <div className='card-container'>
          <div className='icon-title-container'>
            <FontAwesomeIcon size='2xl' icon={faGraduationCap} color='#00AAAF' />
            <p>
              Report CEU Credits
            </p>
          </div>
          <div className='card-body'>
            <div className='m1'>
              <p className='nonBold'>
              Phius Certified Professionals must earn continuing education credits to keep up to date with the latest in passive building. Log your credits to maintain your credentials.{' '}
              </p>
            </div>
            <div>
              <a href='https://forms.zohopublic.com/phius/form/SelfReportCEUs/formperma/vmEEjm8Jympa7RSb2OUOCYaN1fSYqFo4TBMTZKSbEr0'>
                <button className='button-cta-v2' type='button'>
                  Report Credits
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
  )

  useEffect(() => {
    setTimeout(() => {
      setCrmEdit(false);
      setRespMsg('');
    }, 3000);
  }, [respMsg]);

  return Object.keys(user).length > 0 ? (
    <section className='my-dashboard'>
      {/* column 1 */}
        <h2 style={{ fontSize: '24px', fontWeight: '700', marginLeft: '1rem', width: '100%' }}>Hi, {user?.first_name}</h2>
      <div className='my-dashboard-col'>
        {user?.profile_type === 'Certified Professional' && (
          <div style={{ alignItems:'center' , display: 'flex', fontSize: '14px', justifyContent: 'end' }}><LuBadgeCheck color="#00AAAF"/> Displayed on Phius Professional Listing</div>
        )}
        {profileCard}
        {professionalInformationCard}
        {contactInformationCard}
      </div>
      {/* column 2 */}
      <div className='my-dashboard-col' style={user?.profile_type === 'Certified Professional' ? { marginTop: '1rem' } : {}}>
        {reportCEUCredits}
        {user?.profile_type === 'Member' ? membership : becomeAMember}
      </div>
      {/* column 3 */}
      {/* <div className='my-dashboard-col'>{myCertifications}</div> */}

    </section>
  ) : (
    <section style={{ position: 'relative', top: '50%', left: '50%' }}>
      <div className='lds-grid'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
}