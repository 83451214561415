import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
// context would go here
import { UserProvider } from './contexts/UserContext'

// font awesome logos, select from website which to include
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  fas,
  faSignOutAlt,
  faBell,
  faFolder,
  faHome,
  faHardHat,
  faCalendarAlt,
  faBox,
  faComments,
  faUserEdit,
  faAward,
  faUsers,
  faPlusSquare,
  faEdit,
  faBars,
  faUserTimes,
  faTrashAlt,
  faTrophy,
  faExclamationCircle,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  fas,
  faSignOutAlt,
  faHome,
  faEdit,
  faHardHat,
  faCalendarAlt,
  faBox,
  faComments,
  faUserEdit,
  faAward,
  faUsers,
  faPlusSquare,
  faBars,
  faUserTimes,
  faTrashAlt,
  faBell,
  faFolder,
  faTrophy,
  faExclamationCircle,
  faBuilding
);

const container = document.getElementById('root');
const root = createRoot(container);
const environment = process.env.REACT_APP_ENVIRONMENT;
const redirectUrl = environment === 'development' ? window.location.origin : environment === 'staging' ? 'https://staging-34-110-161-45.nip.io' : "https://portal.phius.org";

root.render(
  <React.StrictMode>
    <Auth0Provider 
      domain="https://dev-behcaoqtd8bv8xz8.us.auth0.com"
      clientId="cnYJuXsbjQmIetcZeAOFyKPXm3867b9Q"
      authorizationParams={{
        // redirect_uri: window.location.origin
        redirect_uri: redirectUrl
      }}>
      <UserProvider>
        <Router>
          <App />
        </Router>
      </UserProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
